export default {
  props: {
    listKey: String,
    name: String,
    config: [Object, Boolean],
  },
  methods: {
    onClick() {
      this.$apopup.base({
        title: this.$t(`batch.confirm`),
        bodySlot: () => import('@/components/list/listToolbar/batch/batchPopup.vue'),
        applyCallback: this.batchRequest,
        disabledApply: this.disabledApply,
        targetsText: this.targetsText,
        targets: this.targets,
        hasAnyDisabledTargets: this.hasAnyDisabledTargets,
      })
    },
    async batchRequest() {
      try {
        await this.batchApi(this.targets, this.config.column, this.config.value)
        await this.afterBatchSuccessfully()
        this.$snotify.success(null, this.$t('batch.successfully') )
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t('error.unexpect'),
          this.$t('batch.failure'),
        )
      } finally {
        this.$store.dispatch(`list/${this.listKey}/setSelected`, [])
        this.listActions.index()
      }
    },
    async afterBatchSuccessfully() {
      if(typeof this.config.afterBatchSuccessfully != 'function') return
      try {
        await this.config.afterBatchSuccessfully()
      } catch (error) {
        console.warn(error)
      }
    },
    disabledApply() {
      if(!Array.isArray(this.targets)) return true
      return this.targets.length <= 0
    },
  },
  computed: {
    batchApi() {
      if(typeof this.config.batchRequest === 'function') {
        return this.config.batchRequest
      }
      return this.listApi.batchApi
    },
    label() {
      if(typeof this.config.label === 'function') {
        return this.config.label(this.row)
      }

      return this.config.label || null
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`]
    },
    listApi() {
      return this.$store.getters[`list/${this.listKey}/api`]
    },
    listData() {
      return this.$store.getters[`list/${this.listKey}/data`]
    },
    selected() {
      return this.$store.getters[`list/${this.listKey}/selected`]
    },
    selectedData() {
      const selected_ids = this.selected.map((item) => item.id)
      return this.listData.filter(item => {
        return selected_ids.indexOf(item.id) > -1
      })
    },
    targetsText() {
      if(this.config.contentEmpty === true) return null
      if(typeof this.config.targetLabel != 'function') return null
      const targets = this.selected.map(row => {
        const targetLabel = this.config.targetLabel(row)
        const disabled = typeof this.config.disabled === 'function'
          ? this.config.disabled(row)
          : false

        if(disabled) {
          return `<span class="grey--text font-weight-light"><strike>${targetLabel}</strike></span>`
        }
        return `<span>${targetLabel}</span>`
      })
      return window.eagleLodash.join(targets, '<span>, </span>')
    },
    listTargetKey() {
      return this.$store.getters[`list/${this.listKey}/targetKey`]
    },
    hasAnyDisabledTargets() {
      return this.selected.length != this.targets.length
    },
    targets() {
      return this.selected
        .filter(row => {
          const disabled = typeof this.config.disabled === 'function'
            ? this.config.disabled(row)
            : false
          return !disabled
        })
        .map(row => row[this.listTargetKey])
    },
  },
}
